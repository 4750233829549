<mat-sidenav-container>
  <mat-sidenav *ngIf="hasLoggedIn" mode="over" fixedInViewport="true" [(opened)]="isSideNavOpened">
    <mat-nav-list>
      <a mat-list-item (click)="isSideNavOpened = false" routerLink="profile">Profile</a>
    </mat-nav-list>
  </mat-sidenav>
  
  <mat-sidenav-content>
    <div class="container-fluid">
      <div class="row justify-content-between" *ngIf="showHeader" style="background-color: #800507; color: white; padding-left: 20px; padding-top: 5px; padding-bottom: 10px;">
        <div>
          <div class="float-left" *ngIf="hasLoggedIn" style="margin-top: 20px; cursor:pointer;">
            <svg (click)="isSideNavOpened=!isSideNavOpened" width="30px" height="30px" viewBox="0 0 16 16" class="bi bi-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
          </div>
          <div class="float-left" style="margin-left: 10px; margin-top: 5px;">
            <button mat-button style="font-size: 20px;" (click)="isSideNavOpened = false" routerLink="home">
              <img src="assets/images/CIT-Logo-landscape-white.png" style="width: 180px;">
            </button>
          </div>
        </div>
        <label class="switch" style="margin-top: 15px; margin-right: 15px;">
          <input type="checkbox" [checked]="darkMode$ | async" (change)="onToggleDarkModeService()" />
          <span class="slider round"></span>
        </label>
      </div>
    
      <router-outlet (activate)="modifyHeader()"></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
  