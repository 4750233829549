import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DarkModeService } from 'angular-dark-mode';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  showHeader:boolean = true;
  isSideNavOpened:boolean = false;

  darkMode$: Observable<boolean> = this.darkModeService.darkMode$;

  constructor(private service:SharedService, private router:Router, private darkModeService:DarkModeService) { }

  ngOnInit(): void {
    
  }

  public get hasLoggedIn(): boolean {
    return this.service.hasLoggedIn;
  }

  public onToggleDarkModeService(): void {
    this.darkModeService.toggle();
  }

  modifyHeader() {
    if (this.router.url == '/home' || this.router.url == '/login' || this.router.url == '/profile') {
        this.showHeader = true;
    } else {
        this.showHeader = false;
    }
  }
}
