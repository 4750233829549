<div class="container py-3" style="position: absolute; top: 40%; left: 25%; width: 50%; text-align: center;">
    <div class="sk-folding-cube" style="position: relative;">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
    </div>
    <br>
    {{ stringMessage }}
</div>
