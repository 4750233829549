import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { UrlRedirectInterface } from '../../interfaces/url-redirect';

@Component({
  selector: 'app-redirector',
  templateUrl: './redirector.component.html',
  styleUrls: ['./redirector.component.css']
})
export class RedirectorComponent implements OnInit {

  stringAlias:string;
  stringMessage:string = 'Redirecting';

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.stringAlias = this.router.url;

    // Sanitize the alias
    if (this.stringAlias.charAt(0) === '/')
    {
      this.stringAlias = this.stringAlias.substring(1);
    }

    this.getUrlRedirectByAlias();
  }

  getUrlRedirectByAlias()
  {
    var param = {
      alias : this.stringAlias
    }

    this.service.getUrlRedirectByAlias(param).subscribe((response:UrlRedirectInterface)=>{
      window.location.href = response.value;
    }, err => {
      this.stringMessage = err.error.message;
    });
  }
}
