
import { NgModule } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Created tools
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedService } from './shared.service';

// Externals
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgApexchartsModule } from "ng-apexcharts";

// Materials
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

// Created components
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RedirectorComponent } from './components/redirector/redirector.component';

const Materials = [
  MatSortModule,
  MatListModule,
  MatTabsModule,
  MatSidenavModule,
  MatButtonModule,
  MatProgressBarModule,
  MatAutocompleteModule
]

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    LoginComponent,
    HomeComponent,
    ProfileComponent,
    RedirectorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    Ng2TelInputModule,
    NgApexchartsModule,
    Materials
  ],
  providers: [SharedService, DatePipe, DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
