import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { UserInterface } from './interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  // readonly api_url = "http://localhost:8000";
  // readonly api_url = "https://dev.api.calvin.ac.id";
  readonly api_url = "https://api.calvin.ac.id";

  public hasLoggedIn:boolean = false;
  constructor(private http:HttpClient) { }

  authenticateUser(param:any) {
  	return this.http.post(this.api_url + '/oauth/token', param);
  }

  requestResetPassword(param:any) {
  	return this.http.post(this.api_url + '/hr/user/password/request', param);
  }

  receiveGetResponse(targetURL:string) {
    let headers = new HttpHeaders();
    var accessToken = localStorage.getItem('access_token');
    headers = headers.set('Authorization', 'Bearer ' + accessToken);
    return this.http.get(targetURL, {headers: headers});
  }

  receivePostResponse(targetURL:string, param:any) {
    let headers = new HttpHeaders();
    var accessToken = localStorage.getItem('access_token');
    headers = headers.set('Authorization', 'Bearer ' + accessToken);
    return this.http.post(targetURL, param, {headers: headers});
  }

  receiveDeleteResponse(targetURL:string) {
    let headers = new HttpHeaders();
    var accessToken = localStorage.getItem('access_token');
    headers = headers.set('Authorization', 'Bearer ' + accessToken);
    return this.http.delete(targetURL, {headers: headers});
  }

  getCurrentUser() {
    this.hasLoggedIn = false;

  	return this.receiveGetResponse(this.api_url + '/hr/user');
  }

  checkCurrentUserPermission(user:UserInterface)
  {
    this.hasLoggedIn = true;
    
    // Other logic here
  }

  updatePassword(param:any, id:number) {
    return this.receivePostResponse(this.api_url + '/hr/users/' + id + '/password', param);
  }

  logout() {
  	return this.receivePostResponse(this.api_url + '/hr/user/logout', null);
  }

  getAllUrlRedirects() {
    return this.receiveGetResponse(this.api_url + '/url_redirects');
  }

  getUrlRedirectByAlias(param:any) {
    return this.receivePostResponse(this.api_url + '/redirect', param);
  }

  postUrlRedirect(param:any, id:number)
  {
    if (id == null || id == undefined)
    {
      return this.receivePostResponse(this.api_url + '/url_redirects', param);
    }
    else
    {
      return this.receivePostResponse(this.api_url + '/url_redirects/' + id, param);
    }
  }

  deleteUrlRedirect(id:number) {
    return this.receiveDeleteResponse(this.api_url + '/url_redirects/' + id);
  }
}
