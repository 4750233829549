import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared.service';
import { UserInterface } from '../../interfaces/user';
import { UrlRedirectInterface } from '../../interfaces/url-redirect';
import { ResponseInterface } from '../../interfaces/response';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  searchKeyword:string = '';

  urlRedirectList:UrlRedirectInterface[];
  filteredUrlRedirectList:UrlRedirectInterface[];
  sortedUrlRedirectList:UrlRedirectInterface[];

  errorMessage:string = '';
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;

  urlRedirect:UrlRedirectInterface;
  urlRedirectID:number;
  urlRedirectAlias:string;
  urlRedirectValue:string;
  urlRedirectStartDate:string;
  urlRedirectEndDate:string;

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;

      this.getAllUrlRedirects();
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  getAllUrlRedirects()
  {
    this.service.getAllUrlRedirects().subscribe((response:UrlRedirectInterface[])=>{
      this.urlRedirectList = response;

      this.filterList();
    }, err => {
      alert(err.error.message);
    });
  }

  filterList() {
    var key = this.searchKeyword;

    this.filteredUrlRedirectList = this.urlRedirectList.filter(function(param)
    {
      return param.alias.toLowerCase().includes(
        key.trim().toLowerCase()
      )||param.value.toLowerCase().includes(
        key.trim().toLowerCase()
      )
    });

    this.sortedUrlRedirectList = this.filteredUrlRedirectList.slice();
  }

  sortList(sort: Sort) {
    let data = null;

    if (this.searchKeyword == '')
    {
      data = this.urlRedirectList.slice();
    }
    else
    {
      data = this.filteredUrlRedirectList.slice();
    }
    
    if (!sort.active || sort.direction === '') {
      this.sortedUrlRedirectList = data;
      return;
    }

    this.sortedUrlRedirectList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'alias': return compare(a.alias, b.alias, isAsc);
        case 'value': return compare(a.value, b.value, isAsc);
        case 'start_date': return compare(a.start_date, b.start_date, isAsc);
        case 'end_date': return compare(a.end_date, b.end_date, isAsc);
        default: return 0;
      }
    });
  }

  showModal(item?:UrlRedirectInterface)
  {
    this.hasSuccessfullySubmitted = false;
    this.errorMessage = null;

    if (item != null && item != undefined)
    {
      this.urlRedirect = item;
      this.urlRedirectID = this.urlRedirect.id;
      this.urlRedirectAlias = this.urlRedirect.alias;
      this.urlRedirectValue = this.urlRedirect.value;
      this.urlRedirectStartDate = this.urlRedirect.start_date;
      this.urlRedirectEndDate = this.urlRedirect.end_date;
    }
  }

  closeModal()
  {
    this.urlRedirect = null;
    this.urlRedirectID = null;
    this.urlRedirectAlias = null;
    this.urlRedirectValue = null;
    this.urlRedirectStartDate = null;
    this.urlRedirectEndDate = null;
  }

  submit() {
    var param = {
      alias : this.urlRedirectAlias,
      value : this.urlRedirectValue,
      start_date : this.urlRedirectStartDate,
      end_date : this.urlRedirectEndDate
    }

    if (this.urlRedirectStartDate == '0000-00-00')
    {
      param.start_date = null;
    }

    if (this.urlRedirectEndDate == '0000-00-00')
    {
      param.end_date = null;
    }

    this.isSubmitting = true;

    var id = null;
    if (this.urlRedirect != null && this.urlRedirect != undefined)
    {
      id = this.urlRedirectID;
    }
    
    this.service.postUrlRedirect(param, id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllUrlRedirects();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  delete() {
    this.isSubmitting = true;

    var id = this.urlRedirectID;
    
    this.service.deleteUrlRedirect(id).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.getAllUrlRedirects();
      }
      
      this.isSubmitting = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
