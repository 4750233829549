import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';
import { UserInterface } from '../../interfaces/user';
import { ResponseInterface } from '../../interfaces/response';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  userName:string;
  isInitializing:boolean = true;

  userID:number;
  userCode:string;
  userEmail:string;
  userAccessRole:string;

  userPassword:string;
  userNewPassword:string;

  errorMessage:string = "";
  isSubmitting:boolean = false;
  hasSuccessfullySubmitted:boolean = false;

  constructor(private service:SharedService, private router:Router) { }

  ngOnInit(): void {
    this.checkCurrentUser();
  }

  checkCurrentUser() {
    this.service.getCurrentUser().subscribe((user:UserInterface)=>{
      this.userName = user.name;
      this.userID = user.id;
      this.userEmail = user.email;

      this.service.checkCurrentUserPermission(user);
      this.isInitializing = false;
    }, err => {
      this.isInitializing = false;
      this.router.navigate(['/login']);
    });
  }

  logout() {
    this.isSubmitting = true;
    this.errorMessage = null;

    this.service.logout().subscribe((response:ResponseInterface)=>{
      this.isSubmitting = false;

      if (response.message == 'Successfully logged out.')
      {
        this.router.navigate(['/login']);
      }
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }

  updatePassword() {
    this.isSubmitting = true;
    this.errorMessage = null;

    var param = {
      password : this.userPassword,
      new_password : this.userNewPassword
    }

    this.service.updatePassword(param, this.userID).subscribe((response:ResponseInterface)=>{
      if (response.message == 'ok')
      {
        this.hasSuccessfullySubmitted = true;
        this.isSubmitting = false;
      }
    }, err => {
      this.errorMessage = err.error.message;
      this.isSubmitting = false;
    });
  }
}
