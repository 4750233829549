import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RedirectorComponent } from './components/redirector/redirector.component';

const routes: Routes = [
  { path : 'home', component : HomeComponent },
  { path : 'login', component : LoginComponent },
  { path : 'profile', component : ProfileComponent },
  { path : '**', component: RedirectorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
